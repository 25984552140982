window.__tnt || (window.__tnt = {});
__tnt.map || (__tnt.map = {});
__tnt.map.instances || (__tnt.map.instances = []);

__tnt.map.google = (function(gmaps){
    var Google = function(options) {
        var lib = {};

        // private properties
        var locations = [];
        var map_id = null;
        var map = null;
        var markers = [];
        var local_storage_supported = __tnt.client.capabilities.supportsLocalStorage();
        var stored_locations = [];
        var icon_fill = null;
        var use_latlng = false;
        var hover_offset = 1;
        var geocoder = new gmaps.Geocoder();
        var latlngbounds = new gmaps.LatLngBounds();
        var max_batch = 3;
        var batch_delay = 2000;

        // private methods
        var getLocationsFromStorage = function() {
            if (local_storage_supported) {
                if (localStorage.getItem('tnt-last-geocode')) {
                    var last_geocode = JSON.parse(localStorage.getItem('tnt-last-geocode'));
                    if (Date.now() > last_geocode + 604800000) { // last geocode was longer than 7 days ago
                        localStorage.removeItem('tnt-geocoded-locations');
                        localStorage.removeItem('tnt-last-geocode');
                    }
                }
                if (localStorage.getItem('tnt-geocoded-locations')) {
                    stored_locations = JSON.parse(localStorage.getItem('tnt-geocoded-locations'));
                }
            }
        };

        var createMap = function(map_id, draggable_mobile, street_view) {
            var is_draggable = (window.innerWidth > 480) ? true : false;
            is_draggable = (is_draggable || draggable_mobile) ? true : false;

            map = new gmaps.Map(document.getElementById(map_id), {
                center: new gmaps.LatLng(0, 0),
                zoom: 0,
                scrollwheel: false,
                draggable: is_draggable,
                streetViewControl: street_view,
                mapTypeId: gmaps.MapTypeId.ROADMAP
            });

            getCoords();
        };

        var getCoords = function(index, batch_index) {
            var i = index || 0;
            var bi = batch_index || 0;
            var stored_location = null;

            if (locations[i].address && local_storage_supported) {
                stored_location = loadGeocode(locations[i].address);
            }

            if (stored_location) {
                locations[i].lat = stored_location.lat;
                locations[i].lon = stored_location.lon;
                plotLocation(locations[i]);
                i++;
                if (i < locations.length) getCoords(i, bi);

            } else if (
                (locations[i].lat && locations[i].lon && !locations[i].address)
                || (use_latlng && locations[i].lat && locations[i].lon)
            ) {
                plotLocation(locations[i]);
                i++;
                if (i < locations.length) getCoords(i, bi);

            } else {
                geocode(locations[i], function(results) {
                    locations[i].lat = results[0].geometry.location.lat();
                    locations[i].lon = results[0].geometry.location.lng();
                    if (local_storage_supported) {
                        saveGeocode(locations[i].address, locations[i].lat, locations[i].lon);
                    }
                    plotLocation(locations[i]);
                    i++;
                    bi++;
                    if (i < locations.length) {
                        if (bi >= max_batch) {
                            bi = 0;
                            setTimeout(getCoords, batch_delay, i, bi);
                        } else {
                            getCoords(i, bi);
                        }
                    }
                });
            }
        };

        var geocode = function(location, fn) {
            var address = location.address.replace(/\s+/g,'+').replace(/,/g,'');
            address = encodeURI(address);
            geocoder.geocode({'address':address}, function(results, status) {
                if (status == gmaps.GeocoderStatus.OK) {
                    fn(results);
                } else {
                    __tnt.log('geocode was not successful for the following reason: ' + status);
                }
            });
        };

        var plotLocation = function(location) {
            if (location.lat && location.lon) {
                var latlng = new gmaps.LatLng(location.lat, location.lon);
                latlngbounds.extend(latlng);
                map.fitBounds(latlngbounds);
                createMarker(location);
                adjustZoom();
            }
        };

        var createMarker = function(location) {
            var label = (location.label) ? location.label : null;
            var fill_color = (location.icon_fill) ? location.icon_fill : (icon_fill ? icon_fill : '#EA4335');

            var latlng = new gmaps.LatLng(location.lat, location.lon);

            var markerOptions = {
                position: latlng,
                icon: {
                    path: 'M172.3 501.7C27 291 0 269.4 0 192 0 86 86 0 192 0s192 86 192 192c0 77.4-27 99-172.3 309.7-9.5 13.8-29.9 13.8-39.5 0z',
                    fillColor: fill_color,
                    fillOpacity: 1,
                    anchor: new gmaps.Point(192, 512),
                    strokeWeight: 0,
                    scale: 0.06,
                    labelOrigin: new gmaps.Point(200, 210)
                },
                map: map,
                zIndex: hover_offset
            };

            if (label) {
                markerOptions.label = {
                    text: label.toString(),
                    color: '#ffffff'
                }
            }

            var marker = new gmaps.Marker(markerOptions);

            hover_offset++;

            if (location.html) {
                var infowindow = new gmaps.InfoWindow();
                gmaps.event.addListener(marker, 'click', function () {
                    infowindow.setContent(location.html);
                    infowindow.open(map, marker);
                });
            }

            markers.push(marker);
        };

        var saveGeocode = function(address, lat, lon) {
            var new_location = {
                'address': address,
                'lat': lat,
                'lon': lon
            };

            if (stored_locations.length > 99) {
                stored_locations.splice(0, 1);
            }
            stored_locations.push(new_location);
            localStorage.setItem('tnt-geocoded-locations', JSON.stringify(stored_locations));

            if (!localStorage.getItem('tnt-last-geocode')) {
                localStorage.setItem('tnt-last-geocode', Date.now());
            }
        };

        var loadGeocode = function(address) {
            for (var i=0; i<stored_locations.length; i++) {
                if (stored_locations[i].address === address) {
                    return stored_locations[i];
                }
            }
            return null;
        };

        var adjustZoom = function() {
            var listener = gmaps.event.addListener(map, 'idle', function() {
                if (map.getZoom() > 14) map.setZoom(14);
                gmaps.event.removeListener(listener);
            });
        };

        // public properties
        lib.id = null;

        // public methods
        lib.init = function(options) {
            this.id = options.id;
            locations = options.locations;
            map_id = options.map_id;
            icon_fill = options.icon_fill;
            use_latlng = (options.use_latlng) ? true : false;
            getLocationsFromStorage();
            createMap(map_id, options.draggable_mobile, options.street_view);
            __tnt.map.instances[__tnt.map.instances.length] = this;
        };

        lib.resetView = function() {
            map.fitBounds(latlngbounds);
        };

        lib.focusMarker = function(i) {
            if (i <= markers.length - 1) {
                map.setCenter(markers[i].getPosition());
                map.setZoom(15);
            }
        };

        lib.init(options);

        return lib;
    };

    return Google;
})(google.maps);